<template>
  <div class="h-screen mx-auto antialiased">
    <NavMain class="p-4 md:p-12" />
    <div class="min-h-[calc(100%_-_6rem)]">
      <slot />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  setup() {},
});
</script>
